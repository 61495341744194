@import "../variables";

@mixin close($color: $bg-gray-light) {
  position: relative;
  width: 17px;
  height: 17px;
  cursor: pointer;

  &::before,
  &::after {
    position: absolute;
    top: -2px;
    left: 8px;
    width: 1px;
    height: 21px;
    background-color: $color;
    content: "";
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &:not(.no-hover) {
    &:hover {
      &::before,
      &::after {
        background-color: darken($color, 20%);
      }
    }
  }

  &--small {
    &::before,
    &::after {
      top: 0;
      left: 8px;
      width: 1px;
      height: 17px;
    }
  }

  &--medium {
    width: 9px;
    height: 9px;

    &::before,
    &::after {
      top: -1px;
      left: 4px;
      width: 1px;
      height: 12px;
    }
  }

  &--bold {
    &::before,
    &::after {
      width: 3px;
    }
  }
}
