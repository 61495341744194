@import "../variables";

@mixin plus($color: white) {
  position: relative;
  width: 11px;
  height: 11px;

  &::before,
  &::after {
    position: absolute;
    width: 1px;
    height: 11px;
    background-color: $color;
    content: "";
  }

  &::after {
    transform: rotate(90deg);
  }
}
