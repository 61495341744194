@import "../variables";
@import "./fonts";

@mixin button() {
  @include meiryo-bold;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  padding: 0 15px;
  color: white;
  font-size: 13px;
  line-height: 14px;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  outline: none;
  box-shadow: none;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }

  &--rounded {
    border-radius: 15px;
  }

  &--regular-font {
    @include meiryo-regular;
  }

  &--small {
    height: 20px;
    font-size: 12px;
    line-height: 16px;
  }

  &--medium {
    height: 26px;
    font-size: 12px;
    line-height: 14px;
  }

  &--big {
    height: 30px;
  }

  &--large {
    height: 38px;
  }

  &--larger {
    height: 40px;
  }

  &--white {
    background-color: white;
  }

  &--white-dirty {
    background-color: $pure-white;
  }

  &--outline-gray {
    color: $text-middle-light-soft;
    border: 1px solid $border-gray-lighter;
  }

  &--outline-blue {
    color: $border-blue;
    border: 1px solid $border-blue;
  }

  &--outline-blue-primary {
    color: $primary;
    border: 1px solid $primary;
  }

  &--outline-red {
    color: $bg-red-middle;
    border: 1px solid $bg-red-middle;
  }

  &--blue {
    background-color: $bg-blue;
  }

  &--blue-primary {
    background-color: $primary;
  }

  &--green {
    background-color: $bg-green;
  }

  &--dark {
    background-color: $text-middle-lighter;
  }

  &--gray {
    background-color: $bg-gray-middle;
  }

  &--red {
    background-color: $bg-red-middle;
  }

  &--outline-white {
    color: $pure-white;
    border: 1px solid $pure-white;
  }

  &--bg-transparent {
    background-color: transparent;
  }

  &--hover-blue {
    &:hover {
      color: $pure-white;
      background-color: $bg-blue-soft-alt;
      border: 1px solid $bg-blue-soft-alt;
    }
  }

  &--active-blue {
    &:active {
      color: $pure-white;
      background-color: $bg-blue-dark-alt;
      border: 1px solid $bg-blue-dark-alt;
    }
  }

  &--outlook {
    height: 22px;
    color: $text;
    font-weight: normal;
    font-size: 12px;
    white-space: nowrap;
    background-color: $border-gray;
    border: 1px solid $text-light-softer;
    border-radius: 0;
  }
}
