@import "../mixins/close";
@import "../variables";

.close {
  @include close;

  &--blue {
    @include close($border-blue);
  }
}
