@charset "UTF-8";
.one-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.two-line {
  overflow: hidden;
  display: -webkit-box;
  white-space: normal;
  word-break: break-word;
  -webkit-line-clamp: 2;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}

input[type=checkbox] {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  background-color: white;
  border: 1px solid #9f9f9f;
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input[type=checkbox]::after {
  top: 45%;
  left: 50%;
  display: none;
  width: 20%;
  height: 50%;
  border: solid white;
  border-width: 0 1px 1px 0;
  transform: translate(-50%, -50%) rotate(45deg);
  content: "";
}
input[type=checkbox]:checked {
  background: #007bff;
  border: none;
}
input[type=checkbox]:checked::after {
  position: relative;
  display: block;
}
input[type=checkbox]:hover {
  filter: brightness(90%);
}
input[type=checkbox]:disabled {
  cursor: default;
  opacity: 0.3;
  pointer-events: none;
}
input[type=checkbox].outlook {
  width: 11px;
  height: 11px;
  border: 1px solid #707070;
  border-radius: 0;
}
input[type=date], input[type=time], input[type=month], input[type=number], input[type=text], input[type=password] {
  height: 20px;
  padding: 0 3px;
  color: #333;
  font-size: 12px;
  line-height: 16px;
  background-color: #fafafa;
  border: 1px solid #ccc;
  border-radius: 2px;
  outline: none;
  box-shadow: none;
  font-family: "Meiryo UI", "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, sans-serif;
}
input[type=date]::-webkit-inner-spin-button, input[type=time]::-webkit-inner-spin-button, input[type=month]::-webkit-inner-spin-button, input[type=number]::-webkit-inner-spin-button, input[type=text]::-webkit-inner-spin-button, input[type=password]::-webkit-inner-spin-button {
  align-self: center;
  width: 8px;
  height: 14px;
  background-image: url("/assets/icons/btn_arrows.svg");
  background-size: 100% 100%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
input[type=date][type=number]::-webkit-inner-spin-button, input[type=time][type=number]::-webkit-inner-spin-button, input[type=month][type=number]::-webkit-inner-spin-button, input[type=number][type=number]::-webkit-inner-spin-button, input[type=text][type=number]::-webkit-inner-spin-button, input[type=password][type=number]::-webkit-inner-spin-button {
  display: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
input[type=date]::-webkit-clear-button, input[type=time]::-webkit-clear-button, input[type=month]::-webkit-clear-button, input[type=number]::-webkit-clear-button, input[type=text]::-webkit-clear-button, input[type=password]::-webkit-clear-button {
  width: 11px;
  height: 11px;
  margin-right: 3px;
  background-image: url("/assets/icons/btn_clear.svg");
  background-size: 100% 100%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
input[type=date]::-webkit-calendar-picker-indicator:hover, input[type=time]::-webkit-calendar-picker-indicator:hover, input[type=month]::-webkit-calendar-picker-indicator:hover, input[type=number]::-webkit-calendar-picker-indicator:hover, input[type=text]::-webkit-calendar-picker-indicator:hover, input[type=password]::-webkit-calendar-picker-indicator:hover {
  background: transparent;
}
input[type=date][disabled], input[type=date][readonly], input[type=time][disabled], input[type=time][readonly], input[type=month][disabled], input[type=month][readonly], input[type=number][disabled], input[type=number][readonly], input[type=text][disabled], input[type=text][readonly], input[type=password][disabled], input[type=password][readonly] {
  border: none;
  cursor: default;
}
input[type=radio] {
  width: 14px;
  height: 14px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 50%;
  outline: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input[type=radio]:checked {
  padding: 2px;
  background-color: #007bff;
  background-clip: content-box;
  border-color: #007bff;
}
input[type=radio]:hover {
  filter: brightness(90%);
}
input[type=radio]:disabled {
  background: none;
  border: none;
  cursor: default;
  opacity: 0.6;
  pointer-events: none;
}
input[type=radio]:disabled:checked {
  background-color: #3e84d3;
}

.one-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.two-line {
  overflow: hidden;
  display: -webkit-box;
  white-space: normal;
  word-break: break-word;
  -webkit-line-clamp: 2;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
}

.select {
  display: block;
  height: 30px;
  padding: 0 25px 0 10px;
  color: #a8a8a8;
  color: #333;
  font-size: 13px;
  line-height: 17px;
  background-color: white;
  background-image: url("/assets/icons/icn_chevron_down.svg");
  background-repeat: no-repeat, repeat;
  background-position: right 9px top 50%, 0 0;
  background-size: 12px 12px;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-shadow: none;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.select:hover, .select:focus, .select:active {
  outline: none;
  box-shadow: none;
}
.select:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}
.select--bg-gray {
  background-color: #fafafa;
}
.select--small {
  height: 20px;
  padding: 0 22px 0 5px;
  color: #333;
  font-size: 12px;
  line-height: 16px;
  background-position: right 5px top 50%, 0 0;
  border: 1px solid #ccc;
}

textarea {
  font-family: "Meiryo UI", "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, sans-serif;
  padding: 2px 5px;
  color: #777;
  font-size: 12px;
  background-color: #fafafa;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}
textarea:disabled {
  border: none;
}
textarea.outlook {
  padding: 4px 7px;
  color: #333;
  border: 1px solid #707070;
  border-radius: 0;
}

.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  padding: 0 5px;
  color: white;
  font-size: 13px;
  white-space: nowrap;
  border-radius: 2px;
}
.badge--rectangular {
  border-radius: 0;
}
.badge--pink {
  background-color: #e27ab5;
}
.badge--purple {
  background-color: #926bff;
}
.badge--purple-light {
  background-color: #d06dc0;
}
.badge--purple-light2 {
  background-color: #bf84e7;
}
.badge--red {
  background-color: #bc4747;
}
.badge--red-light {
  background-color: #cc4555;
}
.badge--red-light-alt {
  background-color: #ea6464;
}
.badge--blue {
  background-color: #3e84d3;
}
.badge--blue-light {
  background-color: #1f98e3;
}
.badge--blue-pale {
  background-color: #40b1e8;
}
.badge--green-light {
  background-color: #7cb225;
}
.badge--orange-light {
  background-color: #e3721f;
}
.badge--orange-seg {
  background-color: #ff9d00;
}
.badge--yellow-light {
  background-color: #e2ab52;
}
.badge--dark {
  background-color: #707070;
}
.badge--dark1 {
  background-color: #000;
}
.badge--dark2 {
  background-color: #373737;
}
.badge--dark3 {
  background-color: #5a5a5a;
}
.badge--dark4 {
  background-color: #7d7d7d;
}
.badge--dark5 {
  background-color: #9b9b9b;
}
.badge--dark6 {
  background-color: #b4b4b4;
}
.badge--dark7 {
  background-color: #bebebe;
}
.badge--dark8 {
  background-color: #c8c8c8;
}
.badge--outline-gray {
  color: #bbb;
  border: 1px solid #bbb;
}

.btn {
  font-weight: bold;
  font-family: "Meiryo UI Bold", "Meiryo UI", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  padding: 0 15px;
  color: white;
  font-size: 13px;
  line-height: 14px;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  outline: none;
  box-shadow: none;
  cursor: pointer;
}
.btn:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}
.btn--rounded {
  border-radius: 15px;
}
.btn--regular-font {
  font-family: "Meiryo UI", "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, sans-serif;
}
.btn--small {
  height: 20px;
  font-size: 12px;
  line-height: 16px;
}
.btn--medium {
  height: 26px;
  font-size: 12px;
  line-height: 14px;
}
.btn--big {
  height: 30px;
}
.btn--large {
  height: 38px;
}
.btn--larger {
  height: 40px;
}
.btn--white {
  background-color: white;
}
.btn--white-dirty {
  background-color: #fafafa;
}
.btn--outline-gray {
  color: #757575;
  border: 1px solid #bbb;
}
.btn--outline-blue {
  color: #007bff;
  border: 1px solid #007bff;
}
.btn--outline-blue-primary {
  color: #3e84d3;
  border: 1px solid #3e84d3;
}
.btn--outline-red {
  color: #dc3545;
  border: 1px solid #dc3545;
}
.btn--blue {
  background-color: #1f98e3;
}
.btn--blue-primary {
  background-color: #3e84d3;
}
.btn--green {
  background-color: #41a24d;
}
.btn--dark {
  background-color: #666;
}
.btn--gray {
  background-color: #999;
}
.btn--red {
  background-color: #dc3545;
}
.btn--outline-white {
  color: #fafafa;
  border: 1px solid #fafafa;
}
.btn--bg-transparent {
  background-color: transparent;
}
.btn--hover-blue:hover {
  color: #fafafa;
  background-color: #23a9fc;
  border: 1px solid #23a9fc;
}
.btn--active-blue:active {
  color: #fafafa;
  background-color: #1976b0;
  border: 1px solid #1976b0;
}
.btn--outlook {
  height: 22px;
  color: #333;
  font-weight: normal;
  font-size: 12px;
  white-space: nowrap;
  background-color: #ccc;
  border: 1px solid #707070;
  border-radius: 0;
}

.select {
  display: block;
  height: 30px;
  padding: 0 25px 0 10px;
  color: #a8a8a8;
  color: #333;
  font-size: 13px;
  line-height: 17px;
  background-color: white;
  background-image: url("/assets/icons/icn_chevron_down.svg");
  background-repeat: no-repeat, repeat;
  background-position: right 9px top 50%, 0 0;
  background-size: 12px 12px;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-shadow: none;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
.select:hover, .select:focus, .select:active {
  outline: none;
  box-shadow: none;
}
.select:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}
.select--bg-gray {
  background-color: #fafafa;
}
.select--small {
  height: 20px;
  padding: 0 22px 0 5px;
  color: #333;
  font-size: 12px;
  line-height: 16px;
  background-position: right 5px top 50%, 0 0;
  border: 1px solid #ccc;
}

.plus {
  position: relative;
  width: 11px;
  height: 11px;
}
.plus::before, .plus::after {
  position: absolute;
  width: 1px;
  height: 11px;
  background-color: white;
  content: "";
}
.plus::after {
  transform: rotate(90deg);
}

.close {
  position: relative;
  width: 17px;
  height: 17px;
  cursor: pointer;
}
.close::before, .close::after {
  position: absolute;
  top: -2px;
  left: 8px;
  width: 1px;
  height: 21px;
  background-color: #9f9f9f;
  content: "";
}
.close::before {
  transform: rotate(45deg);
}
.close::after {
  transform: rotate(-45deg);
}
.close:not(.no-hover):hover::before, .close:not(.no-hover):hover::after {
  background-color: #6c6c6c;
}
.close--small::before, .close--small::after {
  top: 0;
  left: 8px;
  width: 1px;
  height: 17px;
}
.close--medium {
  width: 9px;
  height: 9px;
}
.close--medium::before, .close--medium::after {
  top: -1px;
  left: 4px;
  width: 1px;
  height: 12px;
}
.close--bold::before, .close--bold::after {
  width: 3px;
}
.close--blue {
  position: relative;
  width: 17px;
  height: 17px;
  cursor: pointer;
}
.close--blue::before, .close--blue::after {
  position: absolute;
  top: -2px;
  left: 8px;
  width: 1px;
  height: 21px;
  background-color: #007bff;
  content: "";
}
.close--blue::before {
  transform: rotate(45deg);
}
.close--blue::after {
  transform: rotate(-45deg);
}
.close--blue:not(.no-hover):hover::before, .close--blue:not(.no-hover):hover::after {
  background-color: #004a99;
}
.close--blue--small::before, .close--blue--small::after {
  top: 0;
  left: 8px;
  width: 1px;
  height: 17px;
}
.close--blue--medium {
  width: 9px;
  height: 9px;
}
.close--blue--medium::before, .close--blue--medium::after {
  top: -1px;
  left: 4px;
  width: 1px;
  height: 12px;
}
.close--blue--bold::before, .close--blue--bold::after {
  width: 3px;
}

.tick {
  width: 6px;
  height: 9px;
  margin: 0 3px;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  transform: rotate(45deg) translate(-1px, -1px);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100vh;
  font-family: "Meiryo UI", "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, sans-serif;
}

.vertical-scroll-only {
  overflow-x: hidden;
}

.link {
  color: #007bff;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}