@import "../variables";

@mixin select() {
  display: block;
  height: 30px;
  padding: 0 25px 0 10px;
  color: $bg-gray-lighter;
  color: $text;
  font-size: 13px;
  line-height: 17px;
  background-color: white;
  background-image: url("/assets/icons/icn_chevron_down.svg");
  background-repeat: no-repeat, repeat;
  background-position: right 9px top 50%, 0 0;
  background-size: 12px 12px;
  border: 1px solid $border-gray;
  border-radius: 2px;
  box-shadow: none;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  &:hover,
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }

  &--bg-gray {
    background-color: $pure-white;
  }

  &--small {
    height: 20px;
    padding: 0 22px 0 5px;
    color: $text;
    font-size: 12px;
    line-height: 16px;
    background-position: right 5px top 50%, 0 0;
    border: 1px solid $border-gray;
  }
}
