textarea {
  @include meiryo-regular;
  padding: 2px 5px;
  color: $text-light;
  font-size: 12px;
  background-color: $pure-white;
  border: 1px solid $border-gray;
  border-radius: 4px;
  outline: none;

  &:disabled {
    border: none;
  }

  &.outlook {
    padding: 4px 7px;
    color: $text;
    border: 1px solid $text-light-softer;
    border-radius: 0;
  }
}
