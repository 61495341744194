@import "src/scss/variables";

input {
  &[type=checkbox] {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    background-color: white;
    border: 1px solid $bg-gray-light;
    border-radius: 2px;
    outline: 0;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::after {
      top: 45%;
      left: 50%;
      display: none;
      width: 20%;
      height: 50%;
      border: solid white;
      border-width: 0 1px 1px 0;
      transform: translate(-50%, -50%) rotate(45deg);
      content: '';
    }

    &:checked {
      background: $border-blue;
      border: none;

      &::after {
        position: relative;
        display: block;
      }
    }

    &:hover {
      filter: brightness(90%);
    }

    &:disabled {
      cursor: default;
      opacity: 0.3;
      pointer-events: none;
    }

    &.outlook {
      width: 11px;
      height: 11px;
      border: 1px solid $text-light-softer;
      border-radius: 0;
    }
  }

  &[type=date],
  &[type=time],
  &[type=month],
  &[type=number],
  &[type=text],
  &[type=password] {
    height: 20px;
    padding: 0 3px;
    color: $text;
    font-size: 12px;
    line-height: 16px;
    background-color: $pure-white;
    border: 1px solid $border-gray;
    border-radius: 2px;
    outline: none;
    box-shadow: none;

    @include meiryo-regular;

    &::-webkit-inner-spin-button {
      align-self: center;
      width: 8px;
      height: 14px;
      background-image: url('/assets/icons/btn_arrows.svg');
      background-size: 100% 100%;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
    }

    &[type=number] {
      &::-webkit-inner-spin-button {
        display: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
      }
    }

    &::-webkit-clear-button {
      width: 11px;
      height: 11px;
      margin-right: 3px;
      background-image: url('/assets/icons/btn_clear.svg');
      background-size: 100% 100%;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
    }

    &::-webkit-calendar-picker-indicator {
      &:hover {
        background: transparent;
      }
    }

    &[disabled],
    &[readonly] {
      border: none;
      cursor: default;
    }
  }

  &[type=radio] {
    width: 14px;
    height: 14px;
    background-color: white;
    border: 1px solid $border-gray;
    border-radius: 50%;
    outline: 0;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:checked {
      padding: 2px;
      background-color: $border-blue;
      background-clip: content-box;
      border-color: $border-blue;
    }

    &:hover {
      filter: brightness(90%);
    }

    &:disabled {
      background: none;
      border: none;
      cursor: default;
      opacity: 0.6;
      pointer-events: none;

      &:checked {
        background-color: $primary;
      }
    }
  }
}
