@import "./import";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  width: 100%;
  height: 100vh;

  @include meiryo-regular;
}

.vertical-scroll-only {
  overflow-x: hidden;
}

.link {
  color: $border-blue;
  text-decoration: none;
  outline: none;
  cursor: pointer;

  @include line;
}
