@mixin meiryo-regular() {
  font-family: "Meiryo UI", "游ゴシック Medium", YuGothic, YuGothicM, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, sans-serif;
}

@mixin meiryo-bold() {
  font-weight: bold;
  font-family: "Meiryo UI Bold", "Meiryo UI", sans-serif;
}

@mixin helvetica-regular {
  font-family: "Helvetica Neue", sans-serif;
}

@mixin helvetica-medium() {
  font-family: 'Helvetica Neue Medium', sans-serif;
}
