@import "../variables";

@mixin badge() {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
  padding: 0 5px;
  color: white;
  font-size: 13px;
  white-space: nowrap;
  border-radius: 2px;

  &--rectangular {
    border-radius: 0;
  }

  &--pink {
    background-color: $bg-pink;
  }

  &--purple {
    background-color: $bg-purple;
  }

  &--purple-light {
    background-color: $bg-purple-light;
  }

  &--purple-light2 {
    background-color: $bg-purple-light2;
  }

  &--red {
    background-color: $bg-red-alt;
  }

  &--red-light {
    background-color: $bg-red-light;
  }

  &--red-light-alt {
    background-color: $bg-red-light-alt;
  }

  &--blue {
    background-color: $primary;
  }

  &--blue-light {
    background-color: $bg-blue;
  }

  &--blue-pale {
    background-color: $bg-blue-pale;
  }

  &--green-light {
    background-color: $bg-green-middle;
  }

  &--orange-light {
    background-color: $bg-orange-light;
  }

  &--orange-seg {
    background-color: $bg-orange-seg;
  }

  &--yellow-light {
    background-color: $bg-yellow-light;
  }

  &--dark {
    background-color: $text-light-softer;
  }

  &--dark1 {
    background-color: $bg-dark-1;
  }

  &--dark2 {
    background-color: $bg-dark-2;
  }

  &--dark3 {
    background-color: $bg-dark-3;
  }

  &--dark4 {
    background-color: $bg-dark-4;
  }

  &--dark5 {
    background-color: $bg-dark-5;
  }

  &--dark6 {
    background-color: $bg-dark-6;
  }

  &--dark7 {
    background-color: $bg-dark-7;
  }

  &--dark8 {
    background-color: $bg-dark-8;
  }

  &--outline-gray {
    color: $border-gray-lighter;
    border: 1px solid $border-gray-lighter;
  }
}
